import styles from './Footer.module.css'
import { LayoutCenter } from '@betterplace/design-system/server'
import { useTranslations } from 'next-intl'

function Footer() {
  const t = useTranslations('nextjs.core')

  return (
    <footer className={styles.container}>
      <LayoutCenter centeredChildren>{t('provider_info')}</LayoutCenter>
    </footer>
  )
}

export default Footer
