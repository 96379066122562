import Error404 from '@/assets/error404.svg'
import Image, { StaticImageData } from 'next/image'
import styles from './NotFound.module.css'
import { Heading, LayoutStack, TextLink } from '@betterplace/design-system/server'
import { useEffect, useState } from 'react'
import { useTranslations } from 'next-intl'

function NotFound() {
  const t = useTranslations('nextjs')
  const [location, setLocation] = useState<string | undefined>(undefined)

  useEffect(() => {
    setLocation(window.location.href)
  }, [])

  return (
    <LayoutStack space="400">
      <LayoutStack space="200" className={styles.centeredContainer}>
        <Heading level="h100" style={{ font: 'var(--betterplace-typography-heading-md' }}>
          {t('errors.messages.status.404.title')}
        </Heading>
        <p>
          {t('errors.messages.status.404.reasoning')}
          <br></br>
          <span className={styles.location}>{location}</span>
        </p>
      </LayoutStack>
      <div className={styles.imageContainer}>
        <Image src={Error404 as StaticImageData} alt="" fill={true} />
      </div>
      <LayoutStack space="200">
        <Heading level="h200">{t('errors.messages.status.404.todo_headline')}</Heading>
        <ul>
          <li>{t('errors.messages.status.404.todo_1')}</li>
          <li>
            {t('errors.messages.status.404.todo_2')}
            <br></br>
            <TextLink href="mailto:support@betterplace.org">{t('errors.messages.status.404.todo_2_cta')}</TextLink>
          </li>
        </ul>
      </LayoutStack>
    </LayoutStack>
  )
}

export default NotFound
