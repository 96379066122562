import ErrorPageLayout from '../../components/ErrorPageLayout'
import { ComponentType } from 'react'

function WithErrorPageLayout<PropsType extends {}>(component: ComponentType<PropsType>) {
  const Page = component
  const displayName = `WithErrorPageLayout`
  const WrappedComponent: React.FC<PropsType> = function (props) {
    return (
      <ErrorPageLayout>
        <Page {...props} />
      </ErrorPageLayout>
    )
  }
  WrappedComponent.displayName = displayName
  return WrappedComponent
}
// eslint-disable-next-line import/no-unused-modules
export default WithErrorPageLayout
